var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Admin: Audits" } }),
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Audit logs")]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _c(
                "ck-table-filters",
                { on: { search: _vm.onSearch } },
                [
                  _c(
                    "gov-form-group",
                    [
                      _c("gov-label", { attrs: { for: "filter[action]" } }, [
                        _vm._v("Action")
                      ]),
                      _c("gov-select", {
                        attrs: {
                          id: "filter[action]",
                          name: "filter[action]",
                          options: _vm.actions
                        },
                        model: {
                          value: _vm.filters.action,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "action", $$v)
                          },
                          expression: "filters.action"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "extra-filters" },
                    [
                      _c(
                        "gov-form-group",
                        [
                          _c(
                            "gov-label",
                            { attrs: { for: "filter[description]" } },
                            [_vm._v("Description")]
                          ),
                          _c("gov-input", {
                            attrs: {
                              id: "filter[description]",
                              name: "filter[description]",
                              type: "search"
                            },
                            model: {
                              value: _vm.filters.description,
                              callback: function($$v) {
                                _vm.$set(_vm.filters, "description", $$v)
                              },
                              expression: "filters.description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "gov-form-group",
                        [
                          _c(
                            "gov-label",
                            { attrs: { for: "filter[user_id]" } },
                            [_vm._v("User")]
                          ),
                          _vm.loadingUsers
                            ? _c("ck-loader")
                            : _c("gov-select", {
                                attrs: {
                                  id: "filter[user_id]",
                                  name: "filter[user_id]",
                                  options: _vm.users
                                },
                                model: {
                                  value: _vm.filters.user_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "user_id", $$v)
                                  },
                                  expression: "filters.user_id"
                                }
                              })
                        ],
                        1
                      ),
                      _c(
                        "gov-form-group",
                        [
                          _c(
                            "gov-label",
                            { attrs: { for: "filter[oauth_client_id]" } },
                            [_vm._v("Client")]
                          ),
                          _vm.loadingOauthClients
                            ? _c("ck-loader")
                            : _c("gov-select", {
                                attrs: {
                                  id: "filter[oauth_client_id]",
                                  name: "filter[oauth_client_id]",
                                  options: _vm.oauthClients
                                },
                                model: {
                                  value: _vm.filters.oauth_client_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filters,
                                      "oauth_client_id",
                                      $$v
                                    )
                                  },
                                  expression: "filters.oauth_client_id"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ck-resource-listing-table", {
        ref: "auditsTable",
        attrs: {
          uri: "/audits",
          params: _vm.params,
          "default-sort": "-created_at",
          columns: [
            {
              heading: "Action",
              sort: "action",
              render: function(audit) {
                return _vm.formatAction(audit.action)
              }
            },
            {
              heading: "Description",
              sort: "description",
              render: function(audit) {
                return audit.description
              }
            },
            {
              heading: "User",
              sort: "user_full_name",
              render: function(audit) {
                return _vm.formatUserName(audit.user)
              }
            },
            {
              heading: "Date / Time",
              sort: "created_at",
              render: function(audit) {
                return _vm.formatDateTime(audit.created_at)
              }
            }
          ],
          "view-route": function(audit) {
            return {
              name: "audits-show",
              params: { audit: audit.id }
            }
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }